import {FocusStyleManager, H1} from "@blueprintjs/core";
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import React from 'react';
import './App.css';

FocusStyleManager.onlyShowFocusOnTabs();

const App = () => (
  <div className='App bp3-dark'>
    <div style={{marginTop: '100px'}}>
      <H1>Virtual Lattice</H1>
      <br/><br/>
      <span className={'bp3-text-muted'} style={{fontSize: '1.1em'}}>
        We weave cutting-edge solutions into your digital world.
      </span>
      <br/><p/><br/>
      <img className='logo image-fade-in' src={'/logo512.png'} alt={'logo'}/>
      <br/><p/><br/><p/>
      <a className={'bp3-text-muted'} href={'mailto:virtuallattice+site@gmail.com'}>contact us</a>
    </div>
  </div>
);

export default App;
